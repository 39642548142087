import { registerFunctionComponent } from '/js/lib/webact.js';

async function UpdateApp () {
  const { $, css, html, postRender } = this;

  html`
    <span><i18n-text key="updateAvailable"></i18n-text></span>
    <button><i18n-text key="update"></i18n-text></button>
  `;

  css`
    :host {
      background-color: var(--app-colour);
      color: white;
      position: fixed;
      left: 50%;
      bottom: 1rem;
      padding: 1rem;
      transform: translateX(-50%) translateY(150%);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      border: 2px solid var(--app-colour--darker);
      border-radius: 8px;
      display: flex;
      font-size: 10pt;
      pointer-events: none;
      transition: transform 250ms ease;
    }

    :host(.visible) {
      pointer-events: auto;
      transform: translateX(-50%) translateY(0%);
    }

    span {
      flex: 1;
      flex-flow: nowrap;
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      white-space: nowrap;
    }

    button {
      all: unset;
      cursor: pointer;
      transition: color 250ms ease;
    }
  `;

  const reloadApp = () => {
    window.location.reload();
    this.classList.remove('visible');
  };

  postRender(() => {
    document.addEventListener(
      'service-worker:update-available',
      () => $().classList.add('visible')
    );

    $('button').addEventListener(
      'click',
      reloadApp
    );
  });
}

export default registerFunctionComponent(UpdateApp, {
  name: 'update-app'
});
